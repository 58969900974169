/* Theme color vars */
@accentColor: rgba(3, 124, 160, 1);
@accentColor80: rgba(3, 124, 160, 0.8);
@accentColor70: rgba(3, 124, 160, 0.7);
@accentColor50: rgba(3, 124, 160, 0.5);
@accentColor40: rgba(3, 124, 160, 0.4);
@accentColor30: rgba(3, 124, 160, 0.3);
@accentColor20: rgba(3, 124, 160, 0.2);
@accentColor10: rgba(3, 124, 160, 0.1);
@accentColor075: rgba(3, 124, 160, 0.075);
@accentColor5: rgba(3, 124, 160, 0.05);
@accentColor3: rgba(3, 124, 160, 0.03);
@accentColor70Solid: #4FA4BD;
@accentColor10Solid: #E6F2F6;
@accentColor5Solid: #F2F8FA;
@accentHEX: #037CA0;

:root {
  // Colors
  // Primary
  --color-primary-50: #E6F2F6;
  --color-primary-75: #D8EBF0;
  --color-primary-100: #B1D7E2;
  --color-primary-200: #8BC2D3;
  --color-primary-300: #64AEC4;
  --color-primary-400: #3D9AB6;
  --color-primary-500: #1686A7;
  --color-primary-600: #037CA0;
  --color-primary-700: #025F7B;
  --color-primary-800: #024C63;
  --color-primary-900: #01394A;
  --color-primary-1000: #012631;
  --color-primary-1100: #001319;
}

body {
  &.modern-theme {
    #base {
      #content {
        .section-body {
          .contain-lg {
            #formio-form-wizard {
              .formio-form {
                .wizard-page {
                  .formio-component {
                    :is(label, label.col-form-label):not(.form-check-label) {
                      color: @darkTextColor !important;
                      font-size: var(--custom-label-font-size, 16px) !important;
                      font-weight: 400 !important;
                    }
                    &.formio-component-editgrid {
                      .editgrid-listgroup {
                        .list-group-item {
                          &.list-group-header {    
                            > .row {    
                              > div {
                                :is(label, label.col-form-label):not(.form-check-label) {
                                  --custom-label-font-size: 13px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}